/* eslint-disable react/prop-types */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import parse from 'html-react-parser';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

// We're using Gutenberg so we need the block styles
import '@wordpress/block-library/build-style/style.css';
import '@wordpress/block-library/build-style/theme.css';
import { FaChevronLeft } from '@react-icons/all-files/fa/FaChevronLeft';
import { FaChevronRight } from '@react-icons/all-files/fa/FaChevronRight';
import Layout from '../components/Layout/Layout';
import Seo from '../components/Seo';
import Section from '../components/Section/Section';
import PostInformation from '../components/Blog/PostInformation';
import AuthorBox from '../components/Blog/AuthorBox';

export const pageQuery = graphql`
  query BlogPostByIdQuery(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "MMMM DD, YYYY")

      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1110)
            }
          }
        }
      }

      author {
        node {
          uri
          name
          description
          avatar {
            url
          }
        }
      }

      seo {
        title
        metaDesc
      }

      categories {
        nodes {
          name
          uri
        }
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }
  }
`;

const BlogPostPage = ({ data: { previous, next, post } }) => {
  const author = post.author.node;
  const featuredImage = {
    image: getImage(post.featuredImage?.node?.localFile),
    alt: post.featuredImage?.node?.alt || ``,
  };

  return (
    <Layout>
      <Seo title={post.seo.title} description={post.seo.metaDesc} />
      <Section>
        <Container>
          <Row className="c-blog-post-header">
            <Col>
              <h1 className="text-center h3 m-0 fw-bold">
                {parse(post.title)}
              </h1>
              <div className="text-sm-center mt-4">
                <PostInformation
                  author={author}
                  date={post.date}
                  categories={post.categories.nodes}
                />
              </div>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col lg={10}>
              {/* if we have a featured image for this post let's display it */}
              {featuredImage.image && (
                <Row className="mt-4 mb-5 justify-content-center">
                  <Col lg={8}>
                    <GatsbyImage
                      image={featuredImage.image}
                      alt={featuredImage.alt}
                      className="rounded-3"
                    />
                  </Col>
                </Row>
              )}
              {!!post.content && parse(post.content)}
              <div className="my-5">
                <AuthorBox
                  image={author.avatar.url}
                  name={author.name}
                  uri={author.uri}
                  description={author.description}
                />
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-4">
            <Col lg={10}>
              {(previous || next) && (
                <div className="c-post-pagination">
                  <div>
                    {previous && (
                      <div className="c-post-pagination--control c-post-pagination--control">
                        <FaChevronLeft size={16} className="me-1" />
                        <div>
                          <Link
                            to={previous.uri}
                            rel="prev"
                            className="d-block fw-bold fs-5 text-dark c-post-pagination--label"
                          >
                            Previous Post
                          </Link>
                          <Link
                            to={previous.uri}
                            rel="prev"
                            className="font-size-sm text-dark d-none d-lg-inline-block c-post-pagination--title"
                          >
                            {parse(previous.title)}
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>

                  <div>
                    {next && (
                      <div className="c-post-pagination--control text-end">
                        <div>
                          <Link
                            to={next.uri}
                            rel="next"
                            className="d-block fw-bold fs-5 text-dark c-post-pagination--label"
                          >
                            Next Post
                          </Link>
                          <Link
                            to={next.uri}
                            rel="next"
                            className="font-size-sm text-dark d-none d-lg-inline-block c-post-pagination--title"
                          >
                            {parse(next.title)}
                          </Link>
                        </div>
                        <FaChevronRight size={16} className="ms-1" />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  );
};

export default BlogPostPage;
